<script lang="ts">
	import Icon from "../Icon.svelte";
	import truck from "../../../../core/assets/icons/truck.svg?raw";
	import car from "../../../../core/assets/icons/car.svg?raw";
	import lines from "../../../../core/assets/icons/lines.svg?raw";
	import translations from "./ProductButtonGroup.translations.json";
	import { getTranslate } from "../../utils/getTranslate";
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	const translate = getTranslate(translations);

	export let active: "car" | "truck" = "car";
</script>

<div
	class="relative grid h-[5rem] w-full grid-cols-2 rounded-es-[2rem] rounded-se-[2rem] border border-black/5 bg-[#e7e7e7] p-1 md:h-[8.375rem] md:w-[43rem]"
>
	<button
		class="md:text-2md text-text relative z-10 items-center justify-center text-xs leading-9 transition-colors duration-300 ease-in-out focus:outline-none {active !==
		'car'
			? 'text-text/50'
			: ''}"
		class:bg-transparent={active !== "car"}
		on:click={() => {
			active = "car";
			dispatch("change");
		}}
	>
		<span class="flex flex-col gap-2 leading-tight sm:leading-normal">
			<Icon icon={car} class="h-[1.75rem] md:h-[2.1875rem]" />
			<span class="hidden min-[400px]:block">
				{translate("passengerCars")}
			</span>
		</span>
	</button>
	<button
		class="md:text-2md text-text relative z-10 flex items-center justify-center text-xs leading-9 transition-colors {active !==
		'truck'
			? 'text-text/50'
			: ''} duration-500 ease-in-out focus:outline-none"
		class:bg-transparent={active !== "truck"}
		on:click={() => {
			active = "truck";
			dispatch("change");
		}}
	>
		<span class="flex flex-col gap-2 leading-tight">
			<Icon icon={truck} class="h-[2.1875rem]" />
			<span class="hidden min-[400px]:block">
				{translate("commercialVehicles")}
			</span>
		</span>
	</button>
	<div
		class="bg-secondary absolute inset-1 h-[calc(100%-8px)] w-[calc(50%-0.25rem)] rounded-es-[2rem] rounded-se-[2rem] transition-all duration-300 ease-in-out"
		class:translate-x-full={active === "truck"}
	>
		<div class="relative h-full w-full">
			<Icon icon={lines} class="absolute bottom-1/2 left-4 right-4 !aspect-auto translate-y-1/2 md:left-6 md:right-6" />
		</div>
	</div>
</div>
